<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <h1>{{ i18nT(`Bank accounts`) }}</h1>
                            <div class="mb-1">
                <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                  {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                  {{ i18nT(`entries`) }}</span>
                            </div>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <b-dropdown
                                v-if="iCan( 'finances', 'write' )"
                                id="add-new-dropdown"
                                variant="primary"
                                class="float-right"
                                right
                            >
                                <template #button-content>
                                    <feather-icon icon="PlusCircleIcon" />
                                    {{ i18nT(`Add new`) }}
                                </template>

                                <b-dropdown-item @click.prevent="onCreateNewAccount()">
                                    <b-link class="text-primary">
                                        <font-awesome-icon class="mr-25" :icon="['fas', 'plus-circle']" />
                                        {{ i18nT(`Create bank account`) }}
                                    </b-link>
                                </b-dropdown-item>

                                <b-dropdown-item @click.prevent="onConnectNewAccount()">
                                    <b-link class="text-primary">
                                        <font-awesome-icon class="mr-25" :icon="['fas', 'sync-alt']" />
                                        {{ i18nT(`Connect bank account`) }}
                                    </b-link>
                                </b-dropdown-item>

                            </b-dropdown>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            md="4"
                        >
                            <b-form-group
                                v-if="controlVisible"
                                :label="i18nT(`Currency`)">
                                <v-select
                                    v-model="bankCurrency"
                                    :options="bankCurrencies"
                                    label="text"
                                    :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0"
                            >
                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{
                                  i18nT(`Export`)
                                }}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`Excel`) }}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`CSV`) }}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon" />
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refDocumentListTable"
                        :items="fetchDocuments"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >
                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                class="pl-0"
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <h5 class="position-relative mb-0">
                                <small
                                    v-if="data.item.AccountCode"
                                    v-b-tooltip="i18nT(`Connected bank account`)"
                                    class="position-absolute connection-link text-muted"
                                    :class="data.item.AccountCode ? '': 'mr-2'"
                                >
                                    <font-awesome-icon
                                        v-if="data.item.AccountCode"
                                        class="mr-25" :icon="['fas', 'link']" />
                                </small>
                                {{ data.item.Label }}
                            </h5>
                        </template>

                        <template #cell(bank)="data">
                            <h5 class="mb-0">{{ data.item.Bank }}</h5>
                        </template>

                        <template #cell(iban)="data">
                            <h5 class="mb-0">{{ data.item.Iban }}</h5>
                        </template>

                        <template #cell(bic)="data">
                            <h5 class="mb-0">{{ data.item.Bic }}</h5>
                        </template>

                        <template #cell(currency)="data">
                            <h5 class="mb-0">{{ data.item.Currency }}</h5>
                        </template>

                        <template #cell(balance)="data">
                          <h5 class="mb-0" :class="data.item.Balance>0 ? 'text-success' : '' ">
                           {{ {amount: data.item.Balance, currency: data.item.Currency } | currency }}
                            </h5>
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item
                                    v-if="iCan( 'bank', 'write' )"
                                    @click.prevent="onPopupAccount(data.item)">
                                    <feather-icon icon="EditIcon" />
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="data.item.BankCode"
                                    @click.prevent="onSyncAccount(data.item)">
                                    <feather-icon icon="RefreshCcwIcon" />
                                    <span class="align-middle ml-50">{{ i18nT(`Sync`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="false"
                                    @click.prevent="onReconectAccount(data.item)">
                                    <feather-icon icon="RefreshCcwIcon" />
                                    <span class="align-middle ml-50">{{ i18nT(`Reconect`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="data.item.AccountCode"
                                    @click.prevent="onSeeTransactions(data.item)">
                                    <feather-icon icon="ListIcon" />
                                    <span class="align-middle ml-50">{{ i18nT(`See transactions`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="iCan( 'bank', 'write' )"
                                    @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </div>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>

        <!-- MODAL -->
        <b-modal
            id="modal-connect"
            :title="i18nT(`Connect Bank accounts`)"
            size="md"
            ok-title="Connect selected bank accounts"
            ok-only
            no-close-on-backdrop
            @ok="onConnectSelectedBankAccounts"
        >
            <h3>{{ connectedBankAccounts.bank.BankName || '' }}</h3>
            <b-form-checkbox
                v-for="account in connectedBankAccounts.accounts"
                :key="account.id"
                :value="account.id"
                v-model="selectedBankAccounts"
                class="my-1"
            >
                <span v-if="account.name">{{account.name}};</span> <span v-if="account.iban">IBAN: {{ account.iban }}({{ account.currency }})</span>
            </b-form-checkbox>
        </b-modal>

        <!-- MODAL ADD BANK ACCOUNT -->
        <b-modal
            id="modal-account-edit"
            :title="i18nT(`Bank account`)"
            size="lg"
            :ok-title="this.creatingAccount ? i18nT(`Create account`) : i18nT(`Update account`)"
            ok-only
            no-close-on-backdrop
            @ok="onCreateOrUpdateAccount"
        >
            <h4>{{i18nT(`Bank account info`)}}</h4>
            <b-alert
                class="mt-1"
                variant="primary"
                show
            >
                <div class="alert-body">
          <span>
            <b-badge variant="primary">
              {{ i18nT(`Info`) }}
            </b-badge>
            {{ i18nT(`You can manually add bank account information for use in the invoices and quotes you create.`) }}
          </span>
                </div>
            </b-alert>
            <validation-observer ref="accountEditRules">

                <b-form-group
                    :label="i18nT(`Title`)"
                    label-for="accountTitle"
                    class="required"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="title"
                        rules="required"
                    >
                        <b-form-input
                            id="accountTitle"
                            v-model="account.Label"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="i18nT(`Title`)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group
                    :label="i18nT(`Bank`)"
                    label-for="accountBank"
                    class="required"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="bank"
                        rules="required"
                    >
                        <b-form-input
                            id="accountBank"
                            v-model="account.Bank"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="i18nT(`Bank`)"
                            aria-disabled
                            :disabled="disableFields"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group
                    :label="i18nT(`IBAN`)"
                    label-for="accountIban"
                    class="required"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="iban"
                        rules="required"
                    >
                        <b-form-input
                            id="accountIban"
                            v-model="account.Iban"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="i18nT(`IBAN`)"
                            aria-disabled
                            :disabled="disableFields"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group
                    :label="i18nT(`BIC/SWIFT`)"
                    label-for="accountBic"
                >
                    <b-form-input
                        id="accountBic"
                        v-model="account.Bic"
                        :placeholder="i18nT(`BIC/SWIFT`)"
                        aria-disabled
                        :disabled="disableFields"
                    />
                </b-form-group>

                <b-form-group
                    :label="i18nT(`Currency`)"
                    label-for="accountCurrency"
                    class="required"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="currency"
                        rules="required"
                    >
                        <v-select
                            v-model="account.Currency"
                            :options="bankCurrencies"
                            label="text"
                            id="accountCurrency"
                            :reduce="(e) => e.value == '' ? '' : e.value"
                            :state="errors.length > 0 ? false:null"
                            aria-disabled
                            :disabled="disableFields"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </b-modal>

        <b-modal
            id="modal-account"
            title="Connect bank account"
            size="lg"
            ok-title="Connect bank account"
            ok-only
            no-close-on-backdrop
            @ok="onConnectBank"
        >
            <h4>{{i18nT(`Bank account sync settings`)}}</h4>
            <b-alert
                class="mt-1"
                variant="primary"
                show
            >
                <div class="alert-body">
          <span>
            <b-badge variant="primary">
              {{ i18nT(`Info`) }}
            </b-badge>
            {{ i18nT(`You can connect a bank account and automatically sync all transactions.`) }}
          </span>
                </div>
            </b-alert>
            <validation-observer ref="accountRules">
                <b-form-group
                    :label="i18nT(`Country`)"
                    label-for="institutionCountry"
                    class="required"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="institutionCountry"
                        rules="required"
                    >
                        <v-select
                            v-model="institutionCountry"
                            :options="institutionCountries"
                            label="text"
                            id="institutionCountry"
                            :reduce="(e) => e.value == '' ? '' : e.value"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group
                    :label="i18nT(`Bank`)"
                    label-for="institution"
                    class="required"
                    v-if="showBankSelect()"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="institution"
                        rules="required"
                    >
                        <v-select
                            v-model="institution"
                            :options="institutions"
                            label="text"
                            id="institution"
                            :reduce="(e) => e.value == '' ? '' : e.value"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group
                    :label="i18nT(`Period`)"
                    label-for="selectPeriod"
                    class="required"
                    v-if="selectPeriods.length > 0"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="selectPeriod"
                        rules="required"
                    >
                        <v-select
                            v-model="selectPeriod"
                            :options="selectPeriods"
                            label="text"
                            id="selectPeriod"
                            :reduce="(e) => e.value == '' ? '' : e.value"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    // BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BTabs,
    // BTab,
    // BListGroup, BListGroupItem,
    // BCardFooter,
    // BCardBody,
    BFormCheckbox,
    // BFormSelect,
    // BLink,
    BFormInput,
    // BCardHeader,
    BAlert,
    BBadge,
    VBTooltip,
    VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useAccountList from './useAccountList'
import { avatarText } from '@core/utils/filter'
import { dictToSelectArray } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import useAuth from '@/auth/useAuth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

const asyncForEach = async function(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        // BAvatar,
        BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        // BTabs,
        // BTab,
        // BListGroup,
        // BListGroupItem,
        // BCardFooter,
        // BCardBody,
        vSelect,
        BFormCheckbox,
        // BFormSelect,
        BFormInput,
        // BCardHeader,
        BAlert,
        BBadge,
        // VBModal,
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            required,
            account: {
                Label: '',
                Bank: '',
                Iban: '',
                Bic: '',
                Currency: ''
            },
            creatingAccount: false,
            disableFields: false,

            pagination: {},
            selectedItems: [],
            connectedBank: '',
            selectedBankAccounts: [],
            connectedBankAccounts: {
                bank: {},
                accounts: []
            },
            currentItems: [],
            selectAllStatus: false,
            avalableFields: [
                {label: this.i18nT(`Bank`), key: 'bank', visible: true},
                {label: this.i18nT(`Iban`), key: 'iban', visible: true},
                {label: this.i18nT(`Bic`), key: 'bic', visible: true},
                {label: this.i18nT(`Currency`), key: 'currency', visible: true},
                {label: this.i18nT(`Balance`), key: 'balance', visible: true},
            ],
            bankCurrencies: [],
            institutionCountries: [],
            institutionCountry: '',
            institutions: [],
            institution: '',
            selectPeriods: [],
            selectPeriod: '',
            isActionsShow: false,
            software: useAuth.getCurrentSoftware(),
            controlVisible: true,
        }
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('BankAccountTemplates');
          if(storePreferences) {
            this.avalableFields = storePreferences
        }

        this.bankCurrencies.unshift({
            value: '',
            text: this.i18nT(`All`)
        })

        this.$http.get(`system/currencies`).then(({ data }) => {
            this.bankCurrencies.push(...dictToSelectArray(data.data))
        })

        if (this.ref) {
            const formData = new FormData()
            formData.append('reference', this.ref)
            this.$http
                .post(`bankaccounts/confirmBank`, formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.connectedBankAccounts = res.data.data

                    this.$bvModal.show('modal-connect')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        }
        this.fetchDocuments(null, this.determineVisibility);
    },
    computed: {
        computedColumns() {
            let col = [...this.tableColumns]
            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('BankAccountTemplates', this.avalableFields);
            return col
        },
        // emptyHtml() {
        //     return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"+this.i18nT(`No matching records found`)+"</h4>";
        // },
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your accounts. No accounts have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  id='emptyButton'>" + this.i18nT(`Create account`) + "</button>"
        },
        ref() {
            return this.$route.query.ref
        }
    },
    updated(){
      if(document.querySelector('#emptyButton')){
        document.querySelector('#emptyButton').addEventListener('click', this.onCreateNewAccount)
      }

    },
    watch: {
        institutionCountry: function(val) {
            if(!this.showBankSelect()) {
                this.selectPeriods = [{value: 30, text: this.i18nT(`30 days`)}, {value: 60, text: this.i18nT(`60 days`)}, {value: 90, text: this.i18nT(`90 days`)}]
            }
            else {
                this.getInstitutions()
            }
        },
        institution: function() {
            const selectedInstitution = this.institutions.find(
                e => e.value === this.institution
            )
            this.selectPeriods = [
                ...dictToSelectArray(selectedInstitution.selectPeriod)
            ]
        }
    },
    methods: {
        determineVisibility(invoiceArray) {
            if(invoiceArray.length===0){
              this.controlVisible = false;
            }
        },
        onSeeTransactions(account) {
            this.$router.push({ name: 'transactions', query: { account: account.Id } })
        },
        getInstitutionCountries() {
            this.$http.get(`bankaccounts/institutionCountries`).then(({ data }) => {
                this.institutionCountries = [...dictToSelectArray(data.data)]
            })
        },
        showBankSelect() {
            if(!this.institutionCountry || this.institutionCountry == 'FR' || this.institutionCountry == 'ES' || this.institutionCountry == 'BE') {
                return false
            } else {
                return true
            }
        },
        getInstitutions() {
            this.$http
                .get(`bankaccounts/institutions?country=${this.institutionCountry}`)
                .then(({ data }) => {
                    this.institutions = data.data.map(e => ({
                        value: e.id,
                        text: e.name,
                        selectPeriod: e.select_period
                    }))
                })
        },
        getCurrencyName(bankCurrency) {
            const curreny = this.bankCurrencies.find(
                type => type.value == bankCurrency
            )
            return curreny ? curreny.text : ''
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http
                .post(`documents/copyDocument?document_id=${element.item.Id}`)
                .then(() => {
                    this.refetchData()
                })
        },
        onDelete(account) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
				html: `
                    <div id="swal2-content" class="swal2-html-container" style="display: block;">${this.i18nT(`You won't be able to revert this!`)}</div>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" checked class="custom-control-input" id="swal2-checkbox" value="1"/>
                        <label class="custom-control-label ren-label-checkbox swal2-checkbox" for="swal2-checkbox">${this.i18nT(`Delete associated transactions`)}</label>
                    </div>`,
				preConfirm: () => {
					const inputValue = document.getElementById('swal2-checkbox').checked

					return inputValue ? 1 : 0
				},
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {

                if (result.isConfirmed) {
					console.log(result)

                    this.$http.delete(`bankaccounts?id=${account.item.Id}&deleteTransactions=${result.value}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The task has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkCopy() {
            this.$http
                .post(
                    `documents/copyDocument?document_id=${this.selectedItems.join(',')}`
                )
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
				html: `
                    <div id="swal2-content" class="swal2-html-container" style="display: block;">${this.i18nT(`You won't be able to revert this!`)}</div>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" checked class="custom-control-input" id="swal2-checkbox" value="1"/>
                        <label class="custom-control-label ren-label-checkbox swal2-checkbox" for="swal2-checkbox">${this.i18nT(`Delete associated transactions`)}</label>
                    </div>`,
				preConfirm: () => {
					const inputValue = document.getElementById('swal2-checkbox').checked

					return inputValue ? 1 : 0
				},
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete them!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.isConfirmed) {
                    this.$http
                        .delete(`bankaccounts?id=${this.selectedItems.join(',')}&deleteTransactions=${result.value}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The task has been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {},
        onExportCsv() {},
        onReconectAccount() {
            // TODO
        },
        onSyncAccount(account) {
            this.$http
                .get(`transactions/import?bank_account_id=${account.Id}&resync=1`)
                .then(res => {
                    this.refetchData();
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        },


        onPopupAccount(account) {
            this.getInstitutionCountries()
            this.account = JSON.parse(JSON.stringify(account))
            if(this.account.BankCode) {
                this.disableFields = true;
            } else {
                this.disableFields = false;
            }
            this.creatingAccount = false;
            this.$bvModal.show('modal-account-edit')
        },

        onCreateNewAccount(){
            this.getInstitutionCountries();
            this.account = {
                Label: '',
                Bank: '',
                Iban: '',
                Bic: '',
                Currency: ''
            }
            this.disableFields = false;
            this.creatingAccount = true;
            this.$bvModal.show('modal-account-edit')
        },

        onConnectNewAccount(){
            this.getInstitutionCountries()
            this.$bvModal.show('modal-account')
        },
        async onConnectSelectedBankAccounts(bvModalEvent) {
            bvModalEvent.preventDefault()
            if (this.selectedBankAccounts.length > 0) {
                await asyncForEach(this.selectedBankAccounts, async e => {
                    const formData = new FormData()
                    const account = this.connectedBankAccounts.accounts.find(
                        account => account.id === e
                    )
                    formData.append('account_code', account.id)
                    formData.append('agreement_code', account.agreement_code)
                    await this.$http
                        .post(`bankaccounts/connectAccount`, formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                })
                this.$bvModal.hide('modal-connect')
                this.$router.push({ name: 'bank-accounts' })
            } else {
                this.$bvModal.hide('modal-connect')
            }
        },

        onConnectBank(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.$refs.accountRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append('bank_code', this.institution)
                    formData.append('max_historical_days', this.selectPeriod)
                    this.$http
                        .post(`bankaccounts/bankConnect`, formData)
                        .then(res => {
                            this.$bvModal.hide('modal-account')
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            setTimeout(() => {
                                window.location.href = res.data.data.connect_link
                            }, 2000)
                        })
                        .catch(err => {
                            this.$bvModal.hide('modal-account')

                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onCreateOrUpdateAccount() {
            this.$refs.accountEditRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    if (this.account.Id) formData.append('id', this.account.Id)
                    this.account.Currency = this.getCurrencyName(this.account.Currency)

                    for (const prop in this.account) {
                        if (
                            prop !== 'Id' &&
                            this.account[prop] !== '' &&
                            this.account[prop]
                        )
                            formData.append(prop, this.account[prop])
                    }
                    this.$http
                        .post('bankaccounts', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        }
    },
    beforeUnmount(){
        document.getElementById('emptyButton').removeEventListener('click', this.onCreateNewAccount);
    },
    setup() {
        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            employees,
            bankCurrency,
            accounts,
            refetchData
        } = useAccountList()

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            avatarText,
            employees,
            bankCurrency,
            accounts,
            refetchData
        }
    },

}
</script>
<style>
.connection-link {
    top: 1px;
    left: -15px;
}
.vs__clear {
    display: none;
}
</style>
